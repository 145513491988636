import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useDefinitionModal } from "../../../contexts/DefinitionModalContext";
import {
	disablePageScroll,
	enablePageScroll,
} from "scroll-lock/dist/scroll-lock";
import { AnimatePresence, motion } from "framer-motion";
import { h3, meta, richText } from "../../../styles/typography";
import { grid, ratio, screen, spacing } from "../../../styles/mixins";
import { graphql, useStaticQuery } from "gatsby";
import { design } from "../../../styles/settings";
import { colours } from "../../../styles/colours";
import { darken } from "polished";
const ModalContainer = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 100;
	${grid};
`;

const Modal = styled(motion.div)`
	grid-column: 4 / -1;
	background-color: white;
	${grid({ cols: 45 })};
	${spacing("paddingTop", "m")}
	${spacing("paddingBottom", "m")}
	box-shadow: -0.5em 0px 0.5em 0px rgba(0, 0, 0, 0.1);

	@media ${screen("md")} {
		grid-column: -41 / span 40;
		${grid({ cols: 40 })};
	}

	@media ${screen("lg")} {
		grid-column: -33 / span 32;
		${grid({ cols: 32 })};
	}

	@media ${screen("ms")} {
		grid-column: -25 / span 24;
		${grid({ cols: 24 })};
	}

	@media ${screen("mx")} {
		grid-column: -18 / span 17;
		${grid({ cols: 17 })};
		max-width: 1020px;
	}
`;
const Close = styled(motion.button)`
	${ratio(
		"width",
		0,
		{
			mn: { base: 40, ratio: 1 },
			md: { base: 50, ratio: 1 },
			ms: { base: 60, ratio: 1 },
		},
		design
	)};
	${ratio(
		"height",
		0,
		{
			mn: { base: 40, ratio: 1 },
			md: { base: 50, ratio: 1 },
			ms: { base: 60, ratio: 1 },
		},
		design
	)};
	border-radius: 50%;
	border: 1px solid black;
	position: relative;
	${spacing("marginBottom", "m")};
	transition: border-color 400ms ease;

	&:before,
	&:after {
		content: " ";
		position: absolute;
		width: 1px;
		height: 20px;
		background-color: black;
		top: 50%;
		left: 50%;
		transform-origin: 50% 50%;
		transition: background-color 400ms ease;
	}
	&:before {
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
	}
	&:after {
		transform: translateX(-50%) translateY(-50%) rotate(-45deg);
	}

	&:hover,
	&:active {
		border-color: ${colours.highlight};

		&:before,
		&:after {
			background-color: ${colours.highlight};
		}
	}

	&:focus {
		outline: none;
		border-color: ${darken(0.1, colours.highlight)};

		&:before,
		&:after {
			background-color: ${darken(0.1, colours.highlight)};
		}
	}
`;
const Meta = styled.div`
	${meta};
	${spacing("marginBottom", "s")};
	text-transform: uppercase;
	grid-row: 1;
`;
const Title = styled.h1`
	${h3};
	line-height: 1;
	${spacing("marginBottom", "m")};
`;

// const Category = styled.div`
// 	${meta};
// 	position: absolute;
// 	transform: rotate(-90deg);
// 	transform-origin: 0 0;
// 	left: 88.235294118%;
// 	top: 700px;
// 	text-transform: uppercase;
// 	white-space: nowrap;
// `;
const BodyContent = styled.div`
	${richText}
`;
const ContentContainer = styled.div`
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 3 / -8;
	}

	@media ${screen("lg")} {
		grid-column: 2 / -5;
	}
`;

let modalAnimation = {
	enter: {
		x: "0%",
		transition: {
			type: "tween",
			ease: [0.5, 1, 0.89, 1],
			duration: 0.5,
			when: "beforeChildren",
		},
	},
	exit: {
		x: "100%",
		transition: {
			type: "tween",
			ease: [0.5, 1, 0.89, 1],
			duration: 0.5,
			when: "afterChildren",
		},
	},
};

export default function DefinitionModal() {
	const { active, setActive, contentKey, lifeEventsContent } =
		useDefinitionModal();
	const ref = useRef();
	const isOpen = useRef(false);
	const [activeContent, setActiveContent] = useState({});
	const {
		allDefinitionsYaml: { nodes: definitions },
	} = useStaticQuery(graphql`
		query DefinitionQuery {
			allDefinitionsYaml {
				nodes {
					category
					content
					key
					subtitle
					title
				}
			}
		}
	`);

	useEffect(() => {
		let c = definitions.find((d) => d.key === contentKey);
		if (!c) {
			c = lifeEventsContent.find((d) => d.key === contentKey);
		}
		setActiveContent(c ? c : {});
	}, [contentKey, definitions, lifeEventsContent]);

	const { title, subtitle, content } = activeContent;

	useEffect(() => {
		if (active) {
			isOpen.current = true;
			disablePageScroll(ref.current);
		} else if (isOpen.current) {
			isOpen.current = false;
			enablePageScroll(ref.current);
		}
	}, [active]);
	useEffect(() => {
		const current = ref.current;
		return () => {
			enablePageScroll(current);
		};
	}, []);
	return (
		<AnimatePresence exitBeforeEnter>
			{active ? (
				<ModalContainer
					ref={ref}
					initial="exit"
					animate="enter"
					exit="exit"
					onClick={(e) => {
						if (e.target === ref.current) {
							setActive(false);
						}
					}}>
					<Modal variants={modalAnimation}>
						<ContentContainer>
							<Close onClick={() => setActive(false)} />
							<Meta>{subtitle}</Meta>
							<Title>{title}</Title>
							<BodyContent dangerouslySetInnerHTML={{ __html: content }} />
						</ContentContainer>
					</Modal>
				</ModalContainer>
			) : null}
		</AnimatePresence>
	);
}
