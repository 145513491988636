import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { ReactComponent as LogoSvg } from "../../images/logo.svg";
import { meta } from "../../styles/typography";
import Newsletter from "./Newsletter";
import { transparentize } from "polished";
import { useInView } from "react-intersection-observer";
import { useUIState } from "../../contexts/UIStore";
import { getTheme } from "../../styles/mixins";
import { observer } from "mobx-react-lite";

const Wrapper = styled.footer`
	background-color: black;
	color: white;
	width: 100%;
	padding: 56px 0 72px;
	//border-top: 1px solid white;
	position: relative;
	z-index: 10;

	@media screen and (min-width: 768px) {
		padding: 0;
	}
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	padding: 0 20px;

	@media screen and (min-width: 600px) {
		padding: 0 48px;
	}

	@media screen and (min-width: 768px) {
		max-width: 1000px;
	}

	@media screen and (min-width: 1200px) {
		max-width: 1120px;
	}

	@media screen and (min-width: 1800px) {
		max-width: 1430px;
	}
`;

const FooterWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 768px) {
		flex-direction: row;
		width: 100%;
	}
`;
const NewsletterWrapper = styled.div`
	@media screen and (min-width: 768px) {
		padding-top: 72px;
		padding-bottom: 81px;
	}
	@media screen and (min-width: 1200px) {
		flex-grow: 1;
	}
`;
const MenuWrapper = styled.div`
	border-top: 1px solid black;
	padding-top: 40px;
	margin-top: 40px;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 768px) {
		margin-top: 0;
		border-top: none;
		padding-top: 72px;
		padding-bottom: 81px;
		border-left: 1px solid #e9f2ff;
		padding-left: 57px;
		margin-left: 89px;
		min-width: 224px;
	}

	@media screen and (min-width: 1200px) {
		min-width: 242px;
	}

	@media screen and (min-width: 1800px) {
		min-width: 290px;
	}
`;
const LogoWrapper = styled.div`
	width: 160px;
	height: 42px;
	margin: 0 0 12px;

	svg {
		fill: white;
		width: 100%;
		height: 100%;
	}

	@media screen and (min-width: 768px) {
		margin-bottom: 36px;
	}
`;
const Navigation = styled.ul`
	li {
		a {
			color: white;
			font-weight: normal;
			padding: 12px 0;
			display: inline-block;

			@media screen and (min-width: 1200px) {
				font-weight: bold;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
`;
const Copyright = styled.p`
	${meta};
	color: ${transparentize(0.6, "white")};
	margin-top: 30px;
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	@media screen and (min-width: 768px) {
		margin-top: 30px;
	}
`;

const Footer = observer(() => {
	const uiState = useUIState();
	let { inView, ref } = useInView({
		rootMargin: "-50% 0px -50%",
	});

	useEffect(() => {
		// console.log(inView);
		if (uiState.lockNav) return;
		if (inView) {
			uiState.addTheme(getTheme("dark"), "footer");
		} else {
			uiState.removeTheme("footer");
		}
	}, [inView, uiState]);

	return (
		<Wrapper ref={ref}>
			<Container>
				<FooterWrapper>
					<NewsletterWrapper>
						<Newsletter />
					</NewsletterWrapper>
					<MenuWrapper>
						<LogoWrapper>
							<a href="https://atelier.net" target="_blank" rel="noreferrer">
								<LogoSvg />
							</a>
						</LogoWrapper>
						<Navigation>
							<li>
								<a
									href="https://atelier.net/legal-information"
									target="_blank"
									rel="noreferrer">
									Legal Information
								</a>
							</li>
							<li>
								<a
									href="https://atelier.net/data-protection-notice"
									target="_blank"
									rel="noreferrer">
									Data Protection Notice
								</a>
							</li>
							<li>
								<a
									href="https://atelier.net/cookie-policy"
									target="_blank"
									rel="noreferrer">
									Cookie Policy
								</a>
							</li>
						</Navigation>
						<Copyright>
							Copyright L’Atelier {new Date().getFullYear()}
						</Copyright>
					</MenuWrapper>
				</FooterWrapper>
			</Container>
		</Wrapper>
	);
});

export default Footer;
