import React, { createContext, useContext, useRef, useState } from "react";
import { createUIState } from "../stores/UIState";

const UIStoreContext = createContext({});

export function useUIState() {
	return useContext(UIStoreContext);
}

export const UIStore = (props) => {
	const store = useRef(createUIState());
	return <UIStoreContext.Provider value={store.current} {...props} />;
};
