// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-promise-broken-js": () => import("./../../../src/pages/a-promise-broken.js" /* webpackChunkName: "component---src-pages-a-promise-broken-js" */),
  "component---src-pages-economic-opportunities-for-our-avatars-js": () => import("./../../../src/pages/economic-opportunities-for-our-avatars.js" /* webpackChunkName: "component---src-pages-economic-opportunities-for-our-avatars-js" */),
  "component---src-pages-forecasting-fragility-js": () => import("./../../../src/pages/forecasting-fragility.js" /* webpackChunkName: "component---src-pages-forecasting-fragility-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

