import { css } from "@emotion/react";

export default function addFont(
	name,
	woff,
	woff2,
	weight = 400,
	style = "normal",
	unicode = null
) {
	return css`
		@font-face {
			font-family: "${name}";
			src: url("${woff2}") format("woff2"), url("${woff}") format("woff");
			font-weight: ${weight};
			font-style: ${style};
			unicode-range: ${unicode};
		}
	`;
}
