import { br } from "../styles/settings";

// Calculate fluid value interpolating between two values
export const fluid = (
	from,
	to,
	fromBreakpoint = br.mn,
	toBreakpoint = br.mx,
	exact = false,
	viewportWidth = null
) => {
	const slope = (to - from) / (toBreakpoint - fromBreakpoint);
	const base = from - slope * fromBreakpoint;

	if (viewportWidth) {
		return base + viewportWidth * slope;
	} else {
		if (exact) {
			// sometimes we need the exact value
			return `
                calc(${base}px + ${slope * 100}vw)
            `;
		} else {
			// rounded for consistency while responding
			return `
                calc(${base}px + ${Math.round(10000 * slope) * 0.01}vw)
            `;
		}
	}
};

// Calculate fluid value interpolating between two values, using ratio scaling and $sizes map
export const fluidWithRatio = (
	scale,
	breakpoint,
	nextBreakpoint,
	sizes,
	breakpointsList,
	viewportWidth = null
) => {
	if (nextBreakpoint) {
		const fromRatio = sizes[breakpoint].ratio;
		const toRatio = sizes[nextBreakpoint].ratio;

		const from = sizes[breakpoint].base * Math.pow(fromRatio, scale);
		const to = sizes[nextBreakpoint].base * Math.pow(toRatio, scale);

		const fromBreakpoint = breakpointsList[breakpoint];
		const toBreakpoint = breakpointsList[nextBreakpoint];

		return fluid(from, to, fromBreakpoint, toBreakpoint, true, viewportWidth);
	}
};
