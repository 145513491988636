import { makeAutoObservable } from "mobx";

export function createUIState() {
	return makeAutoObservable({
		section: "",
		subSection: "",
		navHeight: 101,
		themeStack: [],
		navOpen: true,
		grain: true,
		lockNav: false,
		windowDimensions: { width: 1024, height: 768 },
		setWindowDimensions(width, height) {
			this.windowDimensions.width = width;
			this.windowDimensions.height = height;
		},
		setNavOpen(val) {
			this.navOpen = val;
		},
		setLockNav(val) {
			this.lockNav = val;
		},
		setNavHeight(height) {
			this.navHeight = height;
		},
		setSection(section) {
			this.section = section;
		},
		setSubSection(subSection) {
			this.subSection = subSection;
		},
		addTheme(theme, id, depth = 1) {
			let index = this.themeStack.findIndex((theme) => {
				return theme.id === id;
			});
			if (index === -1) {
				this.themeStack.push({ id, theme, depth });
				this.themeStack.sort((a, b) => {
					if (a.depth > b.depth) {
						return 1;
					} else if (a.depth < b.depth) {
						return -1;
					}
					return 0;
				});
			}
		},
		removeTheme(id) {
			let index = this.themeStack.findIndex((theme) => {
				return theme.id === id;
			});
			if (index !== -1) {
				this.themeStack.splice(index, 1);
			}
		},
		clearTheme() {
			this.themeStack = [];
		},
		get theme() {
			return this.themeStack.reduce((acc, curr) => {
				return Object.assign({}, acc, curr.theme);
			}, {});
		},
		setGrain(grain) {
			this.grain = grain;
		},
	});
}
