import { css } from "@emotion/react";
import { fontSurt, pStandard } from "../styles/typography";
import { colours } from "../styles/colours";
import addFont from "../styles/addFont";
import RoslindateDisplayWoff from "../fonts/RoslindaleVariable-VF[Display]-no-hints.woff";
import RoslindateDisplayWoff2 from "../fonts/RoslindaleVariable-VF[Display]-no-hints.woff2";
import SurtWoff from "../fonts/Surt-Regular.woff";
import SurtWoff2 from "../fonts/Surt-Regular.woff2";
import SurtSemiWoff from "../fonts/Surt-SemiBold.woff";
import SurtSemiWoff2 from "../fonts/Surt-SemiBold.woff2";
import RoslindateItalicWoff from "../fonts/Roslindale-DisplayCondensedItalic-for.woff";
import RoslindateItalicWoff2 from "../fonts/Roslindale-DisplayCondensedItalic-for.woff2";
import BrixtonWoodWoff from "../fonts/Brixton_Wood-Vector.woff";
import BrixtonWoodWoff2 from "../fonts/Brixton_Wood-Vector.woff2";

import ABCMaxiRoundMonoWoff2 from "../fonts/ABCMaxiRoundMono-Regular.woff2";
import ABCMaxiRoundMonoWoff from "../fonts/ABCMaxiRoundMono-Regular.woff";

const global = css`
	${addFont(
		"Roslindale Display",
		RoslindateDisplayWoff,
		RoslindateDisplayWoff2,
		"100 1000"
	)};
	${addFont(
		"Roslindale Display",
		RoslindateItalicWoff,
		RoslindateItalicWoff2,
		500,
		"italic",
		"U+006F,U+0066,U+0072,U+0062,U+0079"
	)};
	${addFont("Surt", SurtWoff, SurtWoff2)};
	${addFont("Surt", SurtSemiWoff, SurtSemiWoff2, 600)};
	${addFont("SaltySurt", SurtWoff, SurtWoff2, 400, "normal", null, '"salt" on')}
	${addFont(
		"SaltySurt",
		SurtWoff,
		SurtWoff2,
		400,
		"normal",
		"U+30-39",
		'"salt" on'
	)}

	${addFont("Brixton Wood", BrixtonWoodWoff, BrixtonWoodWoff2)};
	${addFont(
		"ABC Maxi Round Mono",
		ABCMaxiRoundMonoWoff,
		ABCMaxiRoundMonoWoff2,
		"400"
	)};

	body {
		${fontSurt};
		${pStandard};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background-color: ${colours.bgWhite};
		color: black;
		&.dark {
			background-color: black;
		}

		&.is-loading {
			overflow: hidden;
		}
	}

	a {
		color: inherit;
		transition: color 400ms ease;
	}
	.visx-tooltip {
		z-index: 1000;
	}
`;

export default global;
