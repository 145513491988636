import { css } from "@emotion/react";
import {
	br,
	spacingSizes,
	fontSizes,
	fontSizesBreakpoints,
	spacingSizesBreakpoints,
	spacingScales,
	design,
	themes,
} from "../styles/settings";
import { fluidWithRatio } from "../styles/functions";
import { isNumber, isString } from "lodash";

export const screen = (breakpoint, breakpointsList = br) =>
	`screen and (min-width: ${breakpointsList[breakpoint]}px)`;

// Calculate values (interpolated) from map (with ratio) and limit it to only between min and max breakpoints
export const ratio = (
	property,
	scale,
	sizes,
	breakpointsList = design,
	minBreakpoint = "mn",
	maxBreakpoint = "mx"
) => {
	// i of sizes object
	let i = 0;
	const listSizes = Object.keys(sizes);

	if (listSizes.length >= 2) {
		const firstBreakpoint = listSizes[i];
		const nextBreakpoint = listSizes[i + 1];

		const query = {};

		// min restriction
		const valueMin = fluidWithRatio(
			scale,
			firstBreakpoint,
			nextBreakpoint,
			sizes,
			breakpointsList,
			br[minBreakpoint]
		);
		query[property] = valueMin;

		if (minBreakpoint) {
			query[screen(minBreakpoint)] = {
				[property]: fluidWithRatio(
					scale,
					firstBreakpoint,
					nextBreakpoint,
					sizes,
					breakpointsList
				),
			};
		}

		// for each sizes breakpoint
		for (const breakpoint of listSizes) {
			if (i + 1 < listSizes.length) {
				const nextBreakpoint = listSizes[i + 1];

				query[`@media ${screen(breakpoint, breakpointsList)}`] = {
					[property]: fluidWithRatio(
						scale,
						breakpoint,
						nextBreakpoint,
						sizes,
						breakpointsList
					),
				};
			}

			i++;
		}

		if (maxBreakpoint) {
			// max restriction
			const valueMax = fluidWithRatio(
				scale,
				listSizes[listSizes.length - 2],
				listSizes[listSizes.length - 1],
				sizes,
				breakpointsList,
				br[maxBreakpoint]
			);

			query[`@media ${screen(maxBreakpoint)}`] = {
				[property]: valueMax,
			};
		}

		return query;
	}
};

export const ratioUncapped = (property, scale, sizes, breakpointsList) => {
	return ratio(property, scale, sizes, breakpointsList, null, null);
};

// Calculate font size value (interpolated) from fontSizes (with ratio)
export const fontSize = (
	scale,
	sizes = fontSizes,
	breakpointsList = fontSizesBreakpoints
) => {
	return ratio("fontSize", scale, sizes, breakpointsList, null, null);
};

// Calculate spacing value (interpolated) from spacingSizes (with ratio)
export const spacing = (
	property,
	size,
	sizes = spacingSizes,
	breakpointsList = spacingSizesBreakpoints,
	scales = spacingScales
) => {
	return ratio(
		property,
		isNumber(size) ? size : scales[size],
		sizes,
		breakpointsList,
		null,
		null
	);
};

// Grid element
export const grid = (props) => css`
	display: grid;
	grid-template-columns: repeat(${props.cols ?? 48}, minmax(0, 1fr));
`;

export const container = (props) => css`
	${grid(props)};
	/* max-width: ${br.mx}; */
	margin-left: auto;
	margin-right: auto;
`;

export const blackOrWhite = (theme, position = "fg") => {
	const fgColour =
		theme === "light" ? "black" : theme === "dark" ? "white" : "";
	const bgColour =
		theme === "light" ? "white" : theme === "dark" ? "black" : "";
	const colour = position === "fg" ? fgColour : bgColour;

	return colour;
};

export const getTheme = (theme) => {
	if (isString(theme)) {
		return themes[theme];
	}
	return theme;
};
