import React, { createContext, useContext, useState } from "react";

const ThemingContext = createContext();
const GetThemeContext = createContext();
const { Provider } = ThemingContext;

export function useTheme() {
	const context = useContext(ThemingContext);

	return context;
}
export function useGetTheme() {
	const context = useContext(GetThemeContext);

	return context;
}

export const Theme = (props) => {
	const [theme, setTheme] = useState("light");

	return (
		<Provider value={setTheme}>
			<GetThemeContext.Provider value={theme} {...props} />
		</Provider>
	);
};

export default Theme;
