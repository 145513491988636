// dev breakpoints
export const br = {
	"mn": 320,
	"sm": 480,
	"md": 768,
	"ml": 1024,
	"lg": 1280,
	"ms": 1680,
	"mx-percentage": 1920,
	"mx": 2200,
};

// design breakpoints
export const design = {
	mn: 375,
	sm: 600,
	md: 900,
	lg: 1440,
	ms: 1920,
	mx: 2560,
};

// currently only used for Standard layout
// font size bases and ratios at different breakpoints
// minimum 2 breakpoints
export const fontSizes = {
	mn: {
		base: 16,
		ratio: 1.19,
	},
	md: {
		base: 17.5,
		ratio: 1.3,
	},
	ms: {
		base: 18,
		ratio: 1.38,
	},
};
export const fontSizesBreakpoints = design;

// spacing sizes bases and ratios at different breakpoints
// minimum 2 breakpoints
export const spacingSizes = {
	mn: {
		base: 8,
		ratio: 1.5,
	},
	md: {
		base: 3,
		ratio: 2,
	},
	ms: {
		base: 4,
		ratio: 2,
	},
};
export const spacingSizesBreakpoints = design;
export const spacingScales = {
	xxs: 0,
	xs: 1,
	s: 2,
	m: 3,
	l: 4,
	xl: 5,
	xxl: 6,
	xxxl: 6.4,
};

export const themes = {
	dark: {
		foreground: "white",
		background: "black",
	},
	light: {
		foreground: "black",
		background: "white",
	},
};
