import React from "react";
import { AnimatePresence } from "framer-motion";
import reset from "../styles/reset";
import global from "../styles/global";
import { Global } from "@emotion/react";
import Footer from "./UI/Footer";
import Header from "./UI/Header";
import Resizer from "../contexts/Resizer";
import DefinitionModalProvider from "../contexts/DefinitionModalContext";
import DefinitionModal from "./UI/DefinitionModal/DefinitionModal";
import NavSize from "../contexts/NavSize";
import Theme from "../contexts/ThemingContext";
import { SectionContext } from "../contexts/SectionContext";

import styled from "@emotion/styled";
import grainBg from "../images/texture-overlay.png";
import { UIStore, useUIState } from "../contexts/UIStore";
import { observer } from "mobx-react-lite";

const GrainOverlay = styled.div`
	position: fixed;
	left: -50vw;
	top: -50%;
	height: 200vh;
	width: 200vw;
	background-image: ${`url(${grainBg})`};
	background-size: 200px;
	z-index: 200;
	opacity: 0.1;
	pointer-events: none;

	.no-grain & {
		display: none;
	}
`;

export default function Layout({ children }) {
	return (
		<>
			<UIStore>
				<Resizer>
					<Header />
					<Global styles={[reset, global]} />
					<DefinitionModalProvider>
						<AnimatePresence exitBeforeEnter initial={false}>
							{children}
						</AnimatePresence>
						<DefinitionModal />
					</DefinitionModalProvider>
					<Footer />
					<GrainOverlay />
				</Resizer>
			</UIStore>
		</>
	);
}
