import React, { createContext, useContext, useState } from "react";

const DefinitionModalContext = createContext();

export const { Provider, Consumer } = DefinitionModalContext;

export function useDefinitionModal() {
	return useContext(DefinitionModalContext);
}

export const DefinitionModalProvider = (props) => {
	const [contentKey, setContentKey] = useState(null);
	const [active, setActive] = useState(false);
	const [lifeEventsContent, setLifeEventsContent] = useState([]);

	return (
		<Provider
			value={{
				active,
				setActive,
				contentKey,
				setContentKey,
				lifeEventsContent,
				setLifeEventsContent,
			}}
			{...props}
		/>
	);
};

export default DefinitionModalProvider;
