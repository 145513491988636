import React, { useEffect, useState } from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { useGetResizer } from "../../contexts/Resizer";
import { useGetTheme } from "../../contexts/ThemingContext";
import styled from "@emotion/styled";
import { useLocation } from "@reach/router";
import { withPrefix } from "gatsby-link";
import { blackOrWhite } from "../../styles/mixins";
const ProgressContainer = styled.div`
	width: 100%;
	height: 4px;
	position: absolute;
	top: 0;
	z-index: 1;
`;
const Bar = styled(motion.div)`
	width: 100%;
	height: 4px;
	background-color: ${(props) => blackOrWhite(props.theme)};
`;
export default function ReadProgress() {
	const viewportScroll = useViewportScroll();
	const location = useLocation();
	const [scrollHeight, setScrollHeight] = useState(100000);
	const area = useGetResizer();
	const theme = useGetTheme();
	useEffect(() => {
		setScrollHeight(document.body.scrollHeight - window.innerHeight);
	}, [area]);
	const position = useTransform(
		viewportScroll.scrollY,
		[0, scrollHeight],
		["-100%", "0%"]
	);
	if (location.pathname === withPrefix("/")) {
		return null;
	}
	return (
		<ProgressContainer>
			<Bar theme={theme} style={{ x: position }} />
		</ProgressContainer>
	);
}
