import React from "react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { isBrowser } from "./src/util/isBrowser";
import ResizeObserver from "resize-observer-polyfill";

if (!("ResizeObserver" in window)) {
	window.ResizeObserver = ResizeObserver;
}

const key = "custom";
const cache = createCache({ key });
cache.compat = true;

if (isBrowser && window.history.scrollRestoration) {
	window.history.scrollRestoration = "manual";
}

export const wrapRootElement = ({ element }) => (
	<CacheProvider value={cache}>{element}</CacheProvider>
);

const transitionDelay = 370;
export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	const savedPosition = getSavedScrollPosition(location);
	if (!location.hash) {
		if (location.key === "initial") {
			window.scrollTo(...(savedPosition || [0, 0]));
		} else {
			window.setTimeout(() => {
				window.scrollTo(...(savedPosition || [0, 0]));
			}, transitionDelay);
		}

		// window.setTimeout(
		// 	() => window.scrollTo(...(savedPosition || [0, 0])),
		// 	transitionDelay
		// );
	}

	return false;
};

const scrollTo = (id) => () => {
	const el = document.querySelector(id);
	if (el) {
		el.scrollIntoView();
	}
	return false;
};

export const onRouteUpdate = ({ location: { hash } }) => {
	if (hash) {
		window.setTimeout(scrollTo(hash), 10);
	}
};
