import React, { createContext, useContext, useState } from "react";

const Context = createContext();
const GetContext = createContext();

const { Provider } = Context;

export function useSection() {
	const context = useContext(Context);

	return context;
}

export function useGetSection() {
	const context = useContext(GetContext);

	return context;
}

export const SectionContext = (props) => {
	const [activeSection, setActiveSection] = useState("");
	return (
		<Provider value={setActiveSection} {...props}>
			<GetContext.Provider value={activeSection} {...props} />
		</Provider>
	);
};

export default Context;
